<template>
  <div class="p-grid card">
    <p-toolbar class="p-mb-4 p-col-12">
      <template #left>
        <h5 class="p-m-0">Categorias</h5>
      </template>

      <template #right>
        <span class="p-d-inline-flex p-mr-2">
          <p-inputtext
            v-model="search.q"
            @keypress.enter="consultar"
            placeholder="Pesquisar..."
          />
          <p-button icon="pi pi-search" @click="consultar" />
        </span>
        <p-button
          @click="dialogCadastro = true"
          icon="pi pi-plus"
          label="Novo"
        />
      </template>
    </p-toolbar>

    <p-datatable
      class="p-col-12"
      :value="data"
      :paginator="true"
      :rows="search.first"
      :lazy="true"
      :totalRecords="paginatorInfo.total"
      :loading="loading"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 200, 500]"
      currentPageReportTemplate="{first} - {last} de {totalRecords}"
      responsiveLayout="scroll"
      stripedRows
    >
      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">
          Nenhum registro encontrado!
        </div>
      </template>

      <p-column field="id" header="ID" :sortable="true" />
      <p-column field="nome" header="Nome" :sortable="true" />
      <p-column
        field="fator_pontuacao"
        header="Fator de Pontuação"
        :sortable="true"
      >
        <template #body="{ data }">
          {{ $formatNumber(data.fatorPontuacao) }}
        </template>
      </p-column>
      <p-column
        field="desconto_afiliado"
        header="Desconto Afiliado (%)"
        :sortable="true"
      >
        <template #body="{ data }">
          {{
            data.descontoAfiliado ? $formatNumber(data.descontoAfiliado) : "-"
          }}
        </template>
      </p-column>
      <p-column
        field="listar_primeiro_pedido"
        header="Listar 1º Pedido"
        :sortable="true"
      >
        <template #body="{ data }">
          {{ data.listarPrimeiroPedido ? "Sim" : "Não" }}
        </template>
      </p-column>
      <p-column field="data_liberacao" header="Liberado" :sortable="true">
        <template #body="{ data }">
          {{ formatDate(data.dataLiberacao) }}
        </template>
      </p-column>
      <p-column field="updated_at" header="Atualizado" :sortable="true">
        <template #body="{ data }">
          {{ new Date(data.updatedAt).toLocaleString() }}
        </template>
      </p-column>
      <p-column field="status" header="Status" :sortable="true" />
      <p-column>
        <template #body="slotProps">
          <div class="p-d-flex p-jc-end">
            <p-button
              class="p-button-danger p-mr-2"
              icon="pi pi-trash"
              @click="excluir(slotProps.data)"
            />
            <p-button icon="pi pi-pencil" @click="editar(slotProps.data)" />
          </div>
        </template>
      </p-column>
    </p-datatable>
  </div>

  <Cadastro
    v-model:visible="dialogCadastro"
    @cadastrar="consultar"
    :id="selecionado?.id"
  ></Cadastro>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { getInstance, Categoria } from "@/services/CategoriaService";
import Cadastro from "./Cadastro.vue";
import { useConsulta, useExcluir } from "@/composables/crud";
import { formatDate } from "@/utils/format";

export default defineComponent({
  components: { Cadastro },
  setup() {
    const {
      data,
      loading,
      search,
      paginatorInfo,
      consultar,
    } = useConsulta<Categoria>(getInstance());
    const { confirmarExcluir } = useExcluir(getInstance());
    const dialogCadastro = ref(false);
    const selecionado = ref<Categoria>();

    search.sortField = "nome";
    search.sortOrder = "ASC";

    watch(dialogCadastro, (v) => !v && (selecionado.value = undefined));

    onMounted(() => consultar());

    return {
      formatDate,
      dialogCadastro,
      data,
      selecionado,
      paginatorInfo,

      loading,
      search,
      consultar,
      onPage(event: any) {
        search.first = event.rows;
        search.page = event.page + 1;
      },
      onSort(event: any) {
        search.sortField = event.sortField;
        search.sortOrder = event.sortOrder > 0 ? "ASC" : "DESC";
      },

      editar(p: Categoria) {
        selecionado.value = p;
        dialogCadastro.value = true;
      },

      async excluir(p: Categoria) {
        (await confirmarExcluir(p.id)) && consultar();
      },
    };
  },
});
</script>
