<template>
  <p-dialog
    :header="`${id ? 'Editar' : 'Cadastrar'} Categoria`"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <form class="form" @submit.prevent="salvar">
      <div class="p-fluid">
        <div class="p-field">
          <label>Nome:</label>
          <p-inputtext v-model="data.nome" required maxlength="30" />
        </div>
        <div class="p-field">
          <label style="width: 100%">
            Fator de Pontuação Padrão
            <br />(valor de compra para gerar 1 ponto)
          </label>
          <p-inputnumber
            v-model="data.fatorPontuacao"
            required
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%">Desconto Afiliado (%)</label>
          <p-inputnumber
            v-model="data.descontoAfiliado"
            required
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%">Listar no 1º Pedido</label>
          <p-inputswitch v-model="data.listarPrimeiroPedido" />
        </div>
        <div class="p-field">
          <label style="width: 100%">Desconto no 1º Pedido</label>
          <p-inputswitch v-model="data.descontoNo1pedido" />
        </div>
        <div class="p-field">
          <label style="width: 100%">Exclusivo no 1º Pedido</label>
          <p-inputswitch v-model="data.exclusivo1pedido" />
        </div>
        <div class="p-field">
          <label style="width: 100%">Data de Liberação:</label>
          <p-inputtext v-model="data.dataLiberacao" type="date" />
        </div>

        <p-divider />
        <div class="p-field">
          <label style="width: 100%">Desconto adicional Ponto de Apoio (%)</label>
          <p-inputnumber
            v-model="data.descontoAdicionalPa"
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%">Exclusivo para Ponto de Apoio:</label>
          <p-inputswitch v-model="data.exclusivoPa" />
        </div>
        <p-divider />

        <div class="p-field">
          <label>Status:</label>
          <p-dropdown
            v-model="data.status"
            :options="status"
            placeholder="Selecione o status"
          />
        </div>
      </div>

      <div class="p-d-flex p-flex-row-reverse p-jc-start">
        <p-button
          label="Salvar"
          :icon="`pi pi-save${saving || loading ? ' p-spin' : ''}`"
          :disabled="saving || loading"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import {
  getInstance,
  Categoria,
  initialCategoria,
} from "@/services/CategoriaService";
import { defineComponent, PropType, watch } from "vue";
import { useCadastro } from "@/composables/crud";

const status = ["ativa", "inativa", "suspensa"];

export default defineComponent({
  emits: ["update:visible", "cadastrar"],
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { data, reset, salvar, loading, saving, load } =
      useCadastro<Categoria>(getInstance(), initialCategoria);

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );
    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    return {
      status,
      saving,
      loading,
      data,
      reset,

      close,
      async salvar() {
        if (await salvar(props.id)) {
          emit("cadastrar");
          close();
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>