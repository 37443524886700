
import {
  getInstance,
  Categoria,
  initialCategoria,
} from "@/services/CategoriaService";
import { defineComponent, PropType, watch } from "vue";
import { useCadastro } from "@/composables/crud";

const status = ["ativa", "inativa", "suspensa"];

export default defineComponent({
  emits: ["update:visible", "cadastrar"],
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { data, reset, salvar, loading, saving, load } =
      useCadastro<Categoria>(getInstance(), initialCategoria);

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );
    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    return {
      status,
      saving,
      loading,
      data,
      reset,

      close,
      async salvar() {
        if (await salvar(props.id)) {
          emit("cadastrar");
          close();
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
